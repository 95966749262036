@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400');
@import url('https://fonts.googleapis.com/css?family=Nanum+Myeongjo:400');
@import url('https://fonts.googleapis.com/css?family=Nanum+Pen+Script:400');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-barun-pen.css');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css');

/*1. 나눔고딕*/
.nanumGothic {
    font-family: "Nanum Gothic";
}

/*2. 나눔명조*/
.nanumMyeongjo {
    font-family: "Nanum Myeongjo";
}

/*3. 나눔바른 펜*/
.nanumBarunpen {
    font-family: 'NanumBarunpen';
}

/*4. 나눔손글씨 펜*/
.nanumPen {
    font-family: "Nanum Pen Script";
}

/*5. 나눔스퀘어*/
.nanumSquare {
    font-family: 'NanumSquare';
}

.arCanvas {
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 100;
}

#requestingCameraPermissions {
    font-size: 1.5em !important;
}

